const ROUTES = [
  {
    name: 'About',
    href: 'about',
  },
  {
    name: 'Projects',
    href: 'projects',
  },
  // {
  //   name: 'Blog',
  //   href: '/#blog',
  // },
];

export default ROUTES;
